.root {
  padding: 0px !important;
  transform-origin: 50% 25%;
  transform: scale(1);
  animation: none !important;
  svg {
    height: 20px;
  }
  &:hover {
    transform: scale(3);
  }
}
