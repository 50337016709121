.root {
  margin-top: var(--negative-top-bar-height);
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to bottom, #0008 92%, #000a 98%);
  padding: 76px;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  .backdrop {
    position: fixed;
    top: 0px;
    height: 96px;
    width: 100vw;
    background: #0005;
    z-index: 1;
    left: 0;
  }
  > :first-child {
    z-index: 1;
  }
  .brand {
    font-family: var(--secondary-font);
    font-size: 64px;
    line-height: 80px;
    margin: 0px;
    color: var(--text-default-color);
  }

  .brandMotto {
    max-width: 390px;
  }

  &,
  & > :first-child,
  .content {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
  }
  & > * {
    height: 100%;
    .content {
      flex: 1;
    }
  }

  button {
    margin: auto;
  }

  .features {
    margin-bottom: 60px;
  }
  > svg,
  > video {
    filter: var(--hand-blur);
    position: absolute;
    height: initial;
    width: 110%;
    @media screen and (max-aspect-ratio: 2094/1123) {
      height: 100%;
      width: initial;
    }
    align-self: center;
  }
}

@media screen and (max-width: 640px) {
  .root {
    padding: 0px var(--screen-padding);
  }
}
