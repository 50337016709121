.root {
  padding-bottom: 90px + 120px;
  padding: var(--screen-padding);
  .store {
    width: 324px;
    height: 285px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white !important;
    box-shadow: var(--card-elevation) !important;
    margin-right: 20px;
    border-radius: 12px;
  }
}
