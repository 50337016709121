.footerLine {
  margin-top: 18px;
  .social {
    display: flex;
    gap: 20px;
    margin-top: -18px;
    a {
      flex-shrink: 0;
      width: 52px;
      height: 52px;
      border-radius: 10px;
      border: 2px solid var(--text-default-color);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 22px;
        --svg-color: var(--text-default-color);
      }
    }
  }
}
.root {
  padding: 140px 0px;
  display: flex;
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  @media screen and (min-width: 1680px) {
    background-size: 100% auto;
  }
  > :first-child {
    margin: auto;
    border-radius: 22px;
    padding: 120px 140px;
    background: var(--glass-bg);
    max-width: 1600px;
    box-sizing: var(--card-elevation);
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .footerLine {
    margin-top: 0px;
    .social {
      margin-top: 0px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .root {
    &,
    & > :first-child {
      padding: 120px var(--screen-padding);
    }
  }
}
