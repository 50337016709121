.root {
  padding: 277px var(--screen-padding) 140px var(--screen-padding);
  background-repeat: no-repeat;
  background-position: center;

  background-size: auto 100%;
  @media screen and (min-width: 1680px) {
    background-size: 100% auto;
  }
}
