.root {
  padding: 120px var(--screen-padding);
  .questions {
    background: var(--glass-bg);
    box-shadow: var(--card-elevation);
    border-radius: 20px;
    margin-top: 185px;
    > * {
      padding: 30px 35px;
      border-bottom: 1px solid rgb(215, 215, 215);
    }
    .questionItem {
      max-width: initial;
    }
    .heading {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .answer {
      padding-top: 24px;
    }
  }
}
